import React from 'react'
import Sidebar from '../Sidebar'
import './style.scss'

class VideoTemplateDetails extends React.Component {
  render() {
    const video = this.props.data.markdownRemark

    return (
      <div>
        <Sidebar {...this.props} />
        <div className="content">
          <div className="content__inner">
            <div className="video">
              <h1 className="video__title">{video.frontmatter.title}</h1>
              <div
                className="video__body"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: video.html+'<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Zeqmt1abdW8?start=2" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VideoTemplateDetails
